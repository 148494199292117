import { useEffect } from "react";

const VisualViewportAPI = () => {

  const baseElementId = 'rootDiv';

  const callScrollHandler = () => {
    if (typeof window.visualViewport === 'undefined') return;
    window.scrollTo({ top: 0, left: 0 });
  }
  
  const callEventHandler = () => {
    if (typeof window.visualViewport === 'undefined') return;

    const element = document.getElementById(baseElementId);
    if (!element) return console.error(`Unable to find element: #${baseElementId}`);

    element.style.height = window.visualViewport!.height + 'px';
    const scrollInterval = setInterval(() => {
      if (window.scrollY >= 1)
        callScrollHandler();
      else 
        clearInterval(scrollInterval);
    }, 100);
    callScrollHandler();
  }

  useEffect(() => {
    if (typeof window.visualViewport === 'undefined') return;

    callEventHandler();
    window.visualViewport?.addEventListener('resize', callEventHandler);
    return () => {
      window.visualViewport?.removeEventListener('resize', callEventHandler);
    }
    // eslint-disable-next-line
  }, [])

  return <></>;
}

export default VisualViewportAPI;