

const App = () => {
  return (
    <div id="rootDiv" className="tw-container bg-zinc-800">
        <div className="flex h-full items-center justify-center text-center">
            <div className="px-4">
              <h1 className="text-4xl text-white mb-4">Visual Viewport API</h1>
              <p>
                The Visual Viewport API provides a way to get the size of the visual viewport and the size of the layout viewport.
              </p>
            </div>
        </div>

        <div className="inputWrapper">
          <input type="text" placeholder="Enter text here..." />
        </div>
    </div>
  )
}

export default App;
